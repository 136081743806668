import { DateTime } from "ts-luxon"

// Endpoints
export const GET_WORKFLOWS="/workflow/workflow"
export const GET_WORKLFOW_SSE="/workflow/status"
export const GET_WORKFLOW_ACTION_SSE="workflow/actions/stream"
export const GET_WORKFLOW_STATISTICS="/workflow/getStatistics?workflowid="
export const GET_WORKFLOW_ACTIONS="/workflow/getActions"
export const GET_TICKET_SSE="/tickets/status"
export const GET_TICKET_LIST="/tickets/getTickets"
export const GET_NOTIFICATION_UPDATEREADBY="/notification/updateReadby"
export const CAMUNDA_INBOUND="/inbound"
export const GET_NOTIFICATION_PER_USER="/notification/userNotifications?user"
export const ZERO_OUT_SUMMARY_REPORT="/zero-out/reports?date="
export const ZERO_OUT_INBOUND_REPORT="/zero-out/reports/inbound-day-summary?"
export const ZERO_OUT_INBOUND_REPORT_TRANSACTIONS="/zero-out/reports/inbound-day-txns"
export const ZERO_OUT_INBOUND_REPORT_PAGE_SIZE=10
export const ZERO_OUT_TIMELINE="/zero-out/timeline"
export const ZERO_OUT_HOLIDAYS="/zero-out/holidays"
export const SSE_PATH="/workflow/sse_updates?user="

// Task Key Names
export const TASK_CREATE_BUNDLE = "create_bundle"
export const TASK_APPROVE_CREATE_BUNDLE = "approve_create_bundle"
export const TASK_UPLOAD_PAYMENT_BUNDLE = "upload_payment_bundle"
export const TASK_UPLOAD_BUNDLE = "upload_bundle"
export const TASK_RESTART_CREATE_BUNDLE = "restart_create_bundle"
export const TASK_RESTART_UPLOAD_PAYMENT_BUNDLE = "restart_upload_payment_bundle"

// Workflow States
export const STATUS_READY="ready"
export const STATUS_IN_PROGRESS="in_progress"
export const STATUS_COMPLETED="completed"
export const STATUS_FAILED="failed"
export const STATUS_SCHEDULED="scheduled"
export const STATUS_PENDING="pending"
export const STATUS_POSTED="posted"

// Zero Out Report States
export const STATUS_ZO_PENDING="Pending"
export const STATUS_ZO_POSTED="Posted"
export const STATUS_ZO_RETURNED="Returned"
export const STATUS_ZO_SETTLED_FOR_TRUSTEE="Settled for Trustee"
export const STATUS_ZO_SETTLED_FOR_TFS="Settled for TFS"
export const STATUS_ZO_SETTLED_FOR_BANK="Settled for Bank"
export const STATUS_ZO_COMPLETED="Completed"

// export const STATUS_READY="READY"
// export const STATUS_IN_PROGRESS="IN_PROGRESS"
// export const STATUS_COMPLETED="COMPLETED"
// export const STATUS_FAILED="FAILED"
// export const STATUS_SCHEDULED="SCHEDULED"
// export const STATUS_PENDING="PENDING"
// export const STATUS_POSTED="POSTED"

// Ticket States
export const TICKET_SOLVED = "solved"
export const TICKET_OPEN = "open"

// Transaction Types
export const INBOUND_ACH="ACH" // Request change
export const MONEY_GRAM="MONEYGRAM" // Request change
export const WAGE_DEDUCTION="WAGE" // Request change
export const DEBIT_CARD="DEBIT" // Request change

// API Response Messages
export const API_SUCCESS_RESPONSE="Success"
export const ZERO_OUT_NOT_FOUND_ERROR="Starting Balance Not found"

// Currency
export const US_DOLLAR_SYMBOL="$"

// Zero Out 
export const TAB_IDS = [INBOUND_ACH, MONEY_GRAM, WAGE_DEDUCTION, DEBIT_CARD]
export const MAX_DATE = DateTime.now().setZone("America/New_York")